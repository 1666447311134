<template>    
    <Settings class="settings"/>
</template>

<script>
    import Settings from '@/components/Settings.vue'

    export default {
        name: 'settings',
        components: {
            Settings
        },
        mounted: function() {
            this.$ebus.$emit("title:set", this.$t("menu.settings"))
        },
        destroyed: function() {
            this.$ebus.$emit("title:reset")
        }
    }
</script>

<style scoped lang="scss">    
    @import 'src/scss/main';
    
    .settings {
        margin-top: $container-top-margin;
    }
</style>