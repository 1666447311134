<template>
    <b-form>
        <b-row class="vacation">
            <b-col>
                <b-row class="label">
                    <b-col cols="1"><b-img center src="img/icons/beach_access_24px.png"/></b-col>
                    <b-col>{{ $t("settings.vacationDate.label") }}</b-col>
                </b-row>
                <b-row class="value">
                    <b-col offset="1">                        
                        <Datetime v-model="vacationDateInput" ref="datepicker"
                            :min-datetime="$moment().add(1, 'days').toISOString()"
                            :phrases="{ok: $t('datepicker.ok'), cancel: $t('datepicker.cancel')}"
                            :input-class="['form-control', {empty: !vacationDateInput}]"
                            input-id="datepicker_input"
                            auto
                        >
                            <span class="vdatetime-icon" slot="before">
                                <b-img src="img/icons/event_24px.png" alt="datepicker icon" class="vdatetime-icon-img"/>
                            </span>
                            <span class="vdatetime-clear" slot="after">
                                <b-button v-if="vacationDateInput" size="sm" variant="link" :title="$t('datepicker.clear')" @click.prevent="clearDateInput">
                                    <b-img src="img/icons/clear_24px.png" alt="datepicker clear date button" />
                                </b-button>
                            </span>
                        </Datetime>
                        <div id="datepicker_hover" @click="popDatepicker" v-if="!vacationDateInput" v-html="$t('datepicker.placeholder')" />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <ButtonRow>
                    <button @click.prevent="save" class="btn btn-success" type="submit" :disabled="isSaveButtonDisabled">{{ $t("button.save") | uppercase}}</button>
                </ButtonRow>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    /* eslint-disable */
    import ButtonRow from '@/components/ButtonRow.vue'
    import {CONFIG} from '../config.js'    
    import { Datetime } from 'vue-datetime'
    import 'vue-datetime/dist/vue-datetime.css'    

    export default {
        data: function() {
            return {
                profile: null,
                vacationDateInput: null,
                vacationDateOrigin: null
            }
        },
        components: { ButtonRow, Datetime },
        watch: {
            profile: function(value) {
                if (value && value[this.vacationDateField]) {
                   this.vacationDateInput = this.$moment(new Date(value[this.vacationDateField])).format()
                   this.vacationDateOrigin = this.vacationDate
                }
            }
        },
        computed: {
            vacationDateField: function() { 
                return "send_offer_disallowed_until"
            },
            vacationDateRequestFormat: function() { 
                return "YYYY-MM-DD"
            },
            vacationDate: function() {
                if (this.vacationDateInput) {
                    try {
                        var moment = this.$moment(this.vacationDateInput)
                        if (moment.isValid()) {
                            return moment
                        }
                    } catch (error) {
                        return null    
                    }
                }

                return null
            },
            isVacationDateValid: function() {
                if (!this.vacationDate) {
                    return true
                }
                
                return this.vacationDate.isAfter(new Date(), "day")
            },
            isSaveButtonDisabled: function() {
                return !this.isVacationDateValid || !this.isVacationDateChanged
            },
            isVacationDateChanged: function() {
                // dates are same but empty
                if (!this.vacationDate && !this.vacationDateOrigin) {
                    return false
                } else if (!this.vacationDate || !this.vacationDateOrigin) {
                    return true
                }

                // check if two non empty dates are same
                return !this.vacationDate.isSame(this.vacationDateOrigin)
            },
            datepickerElement: function() {
                return this.$refs.datepicker.$el.querySelector("#datepicker_input")
            }
        },
        methods: {
            popDatepicker: function() {
                this.datepickerElement.click()
            },
            getProfile: function() {
                var self = this
                this.$protected("get", CONFIG.api.endpoints.profile,
                    function (response) {
                        if (response.data.length) {
                            self.profile = response.data[0]
                        }
                    },
                    {
                        headers: {
                            'Accept-Profile': 'work_agency'
                        }
                    });
            },
            save: function() {
                var self = this;

                var data = {}
                data[this.vacationDateField] = this.vacationDate ? this.vacationDate.format(this.vacationDateRequestFormat) : null

                this.$protected("patch", CONFIG.api.endpoints.profile,
                    function (response) {
                        self.$ebus.$emit('msg:new', {
                            type: "message-default",
                            data: {
                                text: self.vacationDate ? self.$t("settings.vacationDate.success") : self.$t("settings.vacationDate.cancel"),
                                variant: "success"
                            }
                        });

                        self.vacationDateOrigin = self.vacationDate
                    },
                    { 
                        data: data,
                        headers: {
                            'Content-Profile': 'work_agency'
                        }
                    });
            },
            clearDateInput: function() {
                this.vacationDateInput = null
                this.clearMessages();
            },
            clearMessages: function() {
                this.$ebus.$emit('msg:clear'); 
            }
        },
        mounted: function() {
            this.getProfile();

            var self = this
            this.datepickerElement.addEventListener('click', function(event) {
                if (event.target.className != "vdatetime-icon-img") {
                    self.clearMessages()
                }
            })
        },
        filters: {
            uppercase: function(v) {
                return v.toUpperCase();
            }
        }
    }
</script>

<style lang="scss">
    @import 'src/scss/main';

    .vdatetime {
         display: flex;
         align-items: center;
         flex-direction: row;
    }

    .vdatetime-overlay {
        z-index: $z-index-datepicker-overlay !important;
    }

    .vdatetime-popup {
        z-index: $z-index-datepicker-popup !important;
    }

    .vdatetime-input {
        width: 120px;
        text-align: center;
        cursor: pointer;
        border: none;
        background-color: transparent;
        outline: 0
    }

    .vdatetime-input.empty {
        width: auto;
        text-align: left;
    }

    .vdatetime-input:focus {
        border: none;
        background-color: transparent;
        outline: 0;
    }

    .vdatetime-icon {
        margin-right: 10px;
        line-height: 0;
    }

    .vdatetime-clear {
        margin-left: 10px;
        line-height: 0;

        button {
            border: none;
            background-color: transparent;
            padding: 0 !important;
            margin: 0 !important;
            line-height: 0;
        }
    }
</style>

<style scoped lang="scss">
    .section {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 30px;
    }

    #datepicker_hover {
        position: absolute;
        top: 0;
        margin-left: 30px;
        line-height: 38px;
        cursor: pointer;
    }
</style>